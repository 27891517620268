import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { useTranslation } from "react-i18next";
import { Box, css } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { ServiceImageCollectionItem } from "StaticPages/Components/Commons/ServiceImageCollectionItem";
import { theme } from "theme";
import { Spacer } from "mui-extensions/src/Components/Spacer";

export default () => {
    return <>
        <Helmet title="LIVE | VISUALIZ INC" />
        <Layout>
            <Main />
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    return <>
        <main >
            <PageSummary
                src="/images/LIVE_top.jpg"
                description={<>
                    {t("課金システム搭載のLIVEシステム。")}
                    {t("未来をもっと便利に、もっと実⽤的に。")}
                </>}
                title="LIVE配信システム"
                subTitle={t("LIVE")}
            />

            <Box maxWidth="720px" mx="auto">
                <Grid container sx={{ height: "100%" }}>
                    <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                        <Box p={1} my="auto" sx={{ height: "100%" }}>
                            <Box css={blue}>
                                <Spacer />
                                <Typography>{t("特徴１")}</Typography>
                                <Typography>{t("面倒なWebRTCは設置済")}</Typography>
                                <Spacer />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                        <Box p={1} my="auto" sx={{ height: "100%" }}>
                            <Box css={blue}>
                                <Spacer />
                                <Typography>{t("特徴２")}</Typography>
                                <Typography>{t("課金・販売・クラファンで")}</Typography>
                                <Typography>{t("マネタイズ可能")}</Typography>
                                <Spacer />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                        <Box p={1} my="auto" sx={{ height: "100%" }}>
                            <Box css={blue}>
                                <Spacer />
                                <Typography>{t("特徴３")}</Typography>
                                <Typography>{t("テーブル配置・会話機能")}</Typography>
                                <Spacer />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box
                maxWidth="720px"
                p={6}
                mx="auto"
                mt={12}
                border={`2px solid ${theme.palette.text.primary}`}
            >
                <Typography variant="h5" component="h2">{t("LIVE配信システムの主な特徴")}</Typography>
                <Box mt={4}>
                    <Typography variant="body1" component="p">
                        {t("生配信ライブでの販売から会員制配信まで映像配信のマネタイズに対応")}<br />
                        {t("結婚式などに最適なテーブル機能を備えた唯一のサービスです")}<br />
                        {t("8チャンネル4K12SDI対応カメラ映像を")}<br />
                        {t("スイッチャーで配信する本格的な配信も可能な”LIVE”")}
                    </Typography>
                </Box>
            </Box>

            <Box my={12} maxWidth="900px" mx="auto" p={2} >
                <Grid container>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/LIVE_LINE-UP_system.jpg"
                                title={t("課⾦システム搭載")}
                                description={<>
                                    {t("投げ銭システムをはじめ、動画閲覧中に物品の販売なども可能です。")}<br />
                                    {t("さらに割り勘やクラウドファウンディングが即実施可能な機能も実装可能です。")}
                                </>}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/LIVE_LINE-UP_customize.jpg"
                                title={t("テーブル機能搭載")}
                                description={<>
                                    {t("ライブ配信中に仲間との会話が可能なテーブル機能を搭載。")}<br />
                                    {t("簡単な設定で誰もが高機能サービスの提供が可能です。")}
                                </>}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/LIVE_LINE-UP_consulting.jpg"
                                title={t("配信・機材に関する継続的な\nコンサルティング契約")}
                                description={<>
                                    {t("規模に応じて、最適な機材と配信システムを提案いたします。")}<br />
                                </>}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/LIVE_LINE-UP_Saas.jpg"
                                title={t("LIVE配信 SaaS（Software as a Service）について")}
                                description={<>
                                    {t("coming soon")}<br />
                                </>}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box py={12} />
        </main>
    </>;
};

const blue = css({
    color: "white",
    background: "#14188d",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "120px",
});